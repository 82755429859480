<template>
  <v-container class="my-16">
    <v-row justify="center">
      <v-col md="12">
        <v-card color="#55a220" rounded="lg">
          <v-card-title>
            <v-avatar color="success" class="mr-3">
              <v-icon large class="textColor">
                {{ icons.mdiCheckBold }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-h6 ma-0 pa-0" style="line-height: 1">
                <div class="textColor">Sipariş Bilgi</div>
              </h3>
              <small class="text-caption textColor"> Siparişiniz başarılı bir şekilde tarafımıza iletilmiştir </small>
            </div>
          </v-card-title>

          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="2">
                <v-list color="#398604" rounded dense class="px-0" style="height: 100%">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon class="textColor">
                        {{ icons.mdiQrcodeScan }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle class="textColor">Sipariş No</v-list-item-subtitle>
                      <v-list-item-title class="textColor">
                        <v-chip label x-small color="white" class="textColor">
                          <div class="black--text">
                            {{ detail.head.id }}
                          </div>
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="3">
                <v-list color="#398604" rounded dense class="px-0" style="height: 100%">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon class="textColor">
                        {{ icons.mdiCalendar }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle class="textColor">Sipariş Tarihi</v-list-item-subtitle>
                      <v-list-item-title class="textColor">
                        <v-chip label x-small color="white" class="textColor">
                          <div class="black--text">
                            {{ detail.head.siparis_tarihi }}
                          </div>
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="2">
                <v-list color="#398604" rounded dense class="px-0" style="height: 100%">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon class="textColor">
                        {{ icons.mdiCalendarCheckOutline }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle class="textColor">Teslim Tarihi</v-list-item-subtitle>
                      <v-list-item-title class="mb-2 textColor">
                        <v-chip label x-small color="white" class="textColor">
                          <div class="black--text">
                            {{ detail.head.teslim_tarihi.slice(0, 10) }}
                          </div>
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="3">
                <v-list color="#398604" rounded dense class="px-0" style="height: 100%">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon class="textColor">
                        {{ icons.mdiContactlessPayment }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle class="textColor">Ödeme</v-list-item-subtitle>
                      <v-list-item-title v-if="detail.head.odeme_tipi === '1'" class="textColor">
                        Kredi Kartı
                      </v-list-item-title>
                      <v-list-item-title v-if="detail.head.odeme_tipi === '2'">
                        <v-chip two-lines label x-small color="white" class="textColor">
                          <div class="black--text">
                            Cari Hesaba Mahsusen
                            <strong>{{ xx }}</strong> GÜN
                          </div>
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-title v-if="detail.head.odeme_tipi === '2'"> </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="2">
                <v-list color="#398604" rounded dense class="px-0" style="height: 100%">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon class="textColor">
                        {{ icons.mdiTruckFast }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle class="textColor">Teslimat Tipi</v-list-item-subtitle>

                      <v-list-item-title>
                        <v-chip label x-small color="white">
                          <span class="black--text">
                            {{ detail.head.kargo_bilgisi.kargo_adi }}
                          </span>
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col md="12">
        <v-card color="grey lighten-1" rounded="lg">
          <v-card-title>
            <v-avatar color="secondary" class="mr-3">
              <v-icon :size="24" class="textColor">
                {{ icons.mdiFormatListText }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-h6 ma-0 pa-0" style="line-height: 1">Sipariş Listesi</h3>
              <small class="text-caption"> Sipariş ettiğiniz ürünlerin listesi </small>
            </div>
          </v-card-title>
          <v-card-text>
            <v-list color="transparent" dense class="pa-0">
              <div v-for="(line, index) in detail.lines" :key="`LIST-${index}`">
                <v-hover v-slot="{ hover }">
                  <v-list-item
                    :three-line="!$vuetify.breakpoint.mdAndUp"
                    :two-line="$vuetify.breakpoint.mdAndUp"
                    :class="hover ? 'null' : null"
                    class="py-2 py-md-0 px-0 px-md-2"
                  >
                    <v-list-item-action>
                      <v-sheet :max-width="$vuetify.breakpoint.mdAndUp ? 120 : 80" color="transparent">
                        <CounterTo
                          :count="false"
                          :uid="line.uid"
                          :plant-id="line.selected_plant"
                          :miktar="line.qty"
                          :birim="line.birim"
                          :birim-ritim="line.birim_ritmi"
                          bg-color="counter"
                          button-color="black"
                          :light="true"
                          @refreshBasket="refreshBasket()"
                        />
                      </v-sheet>
                    </v-list-item-action>
                    <v-list-item-content :class="$vuetify.breakpoint.mdAndUp ? 'ml-6' : 'ml-2'">
                      <v-list-item-title
                        class="text-caption text-md-body-2 font-weight-thin pt-1"
                        :style="$vuetify.breakpoint.mdAndUp ? null : 'white-space: normal !important'"
                      >
                        {{ line.baslik }}
                      </v-list-item-title>

                      <v-list-item-subtitle class="mt-1">
                        {{ line.total_price | currency }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-hover>
                <v-divider class="hre"></v-divider>
              </div>

              <v-divider class="hre"></v-divider>
              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right"> Toplam Tutar </v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ detail.head.total_price | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="hre"></v-divider>
              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right"> KDV </v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ detail.head.tax_price | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="hre"></v-divider>
              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right">
                    <p class="pa-0 ma-0">Sipariş Toplam Tutarı</p>
                    <small class="text--disabled">(Geri Kazanım Katkı Payı Eklenecektir)</small>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ detail.head.order_price | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="12" style="max-height: 320px">
        <v-alert outlined class="rounded-lg" height="100%">
          <v-card color="transparent" flat rounded="lg" height="100%">
            <v-card-title>
              <v-icon class="mr-3">
                {{ icons.mdiMapMarker }}
              </v-icon>

              <div class="text-h6 black--text">Teslimat Adresi</div>
            </v-card-title>

            <v-card-text>
              <v-list class="pa-0" color="transparent">
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold"> Alıcı Adı </v-list-item-subtitle>
                    <div style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.name }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item three-line class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold"> Adres </v-list-item-subtitle>
                    <div style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.address }} {{ detail.head.teslimat_adresi.state }} /
                      {{ detail.head.teslimat_adresi.city_txt }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold"> Alıcı Telefonu </v-list-item-subtitle>
                    <div style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.mobile }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-alert>
      </v-col>
      <!--
      <v-col cols="12" md="6" style="max-height: 320px">
        <v-alert outlined height="100%">
          <v-card color="transparent" flat rounded="lg" height="100%">
            <v-card-title>
              <v-icon class="mr-3">
                {{ icons.mdiReceipt }}
              </v-icon>

              <div class="text-h6 ma-0 pa-0 black--text">Fatura Bilgileri</div>
            </v-card-title>

            <v-card-text>
              <v-list class="pa-0" color="transparent">
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold"> Fatura Adı </v-list-item-subtitle>
                    <div style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.fatura_adresi.name }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item three-line class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold"> Adres </v-list-item-subtitle>
                    <div style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.address }} {{ detail.head.teslimat_adresi.state }} /
                      {{ detail.head.teslimat_adresi.city_txt }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold"> Vergi Dairesi / Numarası </v-list-item-subtitle>
                    <div style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.state }} / {{ detail.head.teslimat_adresi.mobile }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-alert>
      </v-col>
       -->
    </v-row>

    <v-row v-if="detail.head.buyer_note !== 'null'" justify="center">
      <v-col cols="12" md="12">
        <v-alert text outlined color="error"> Sipariş Notu : {{ detail.head.buyer_note }} </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CounterTo from '@/components/CounterTo.vue'
import store from '@/store'
import orderStoreModule from '@/views/order/orderStoreModule'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import {
  mdiCalendar,
  mdiCalendarCheckOutline,
  mdiCheckBold,
  mdiContactlessPayment,
  mdiFormatListText,
  mdiMapMarker,
  mdiQrcodeScan,
  mdiReceipt,
  mdiTruckFast,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import { onMounted, ref } from '@vue/composition-api'

export default {
  components: {
    CounterTo,
  },

  setup() {
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-order'
    const { isDark } = useAppConfig()
    const detail = ref({})
    const { router } = useRouter()
    const status = ref(false)
    const xx = ref('')

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, orderStoreModule)
    }
    onMounted(() => {
      store.dispatch('app-order/fetchGetOrderStep4Data').then(response => {
        if (response.detail.error === 1) {
          router.push({ name: 'dashboard' })
        } else {
          detail.value = response.detail
          xx.value = response.detail.head.paymcond

          status.value = true
          store.dispatch('app-order/fetchBasketItemCount')
        }
      })
    })

    return {
      status,
      isDark,
      xx,
      detail,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      appLogoDark: themeConfig.app.logoDark,
      textLogo: themeConfig.app.textLogo,
      textLogoDark: themeConfig.app.textLogoDark,
      icons: {
        mdiCalendar,
        mdiFormatListText,
        mdiReceipt,
        mdiMapMarker,
        mdiCheckBold,
        mdiQrcodeScan,
        mdiTruckFast,
        mdiCalendarCheckOutline,
        mdiContactlessPayment,
      },

      // Icons
    }
  },
}
</script>

<style>
.textColor {
  color: white !important;
}
.hre {
  opacity: 1;
  margin: 0;
  color: black;
}
</style>
